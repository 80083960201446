import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-topo',
  templateUrl: './topo.component.html',
  styleUrls: ['./topo.component.css']
})
export class TopoComponent implements OnInit {

  cached_at;

  constructor(private sharedService: SharedService) {
    this.sharedService.callComponentTopo$.subscribe((data) => {
      this.cached_at = new Date(data);
    });
  }

  ngOnInit() {}

}
