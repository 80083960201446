import { Injectable, Input, Output, EventEmitter } from '@angular/core';
import { MapaService } from './mapa.service';
import { GraficoService } from './grafico.service';
import { Subject } from 'rxjs';

@Injectable()
export class SharedService {

  @Output() fire: EventEmitter<any> = new EventEmitter();
  sensor = 22;
  periodo = '1h';
  public estacoes;

  public params = {
    sensor: 22,
    sensor_selected: 22,
    periodo: '1h',
    instituicoes: '41',
    operador: null,
    init: false
  };
  private callComponentTopo = new Subject<any>();
  callComponentTopo$ = this.callComponentTopo.asObservable();

  private callChartPeriodoTarget = new Subject<any>();
  public callChartPeriodoTarget$ = this.callChartPeriodoTarget.asObservable();

  private callChartEstacao = new Subject<any>();
  public callChartEstacao$ = this.callChartEstacao.asObservable();

  private callComponentGrafico = new Subject<any>();
  public callComponentGrafico$ = this.callComponentGrafico.asObservable();

  constructor(private mapaService: MapaService, private graficoService: GraficoService) {
    this.mapaService.sensor = this.sensor;
    this.mapaService.periodo = this.periodo;
  }

  changeZoom(zoom, lat, lon) {
    this.mapaService.changeZoom(zoom, lat, lon);
  }

  changeFilters(sensor, periodo) {
    this.params.sensor = sensor;
    this.params.periodo = periodo;
    this.mapaService.periodo = periodo;
    this.mapaService.sensor = sensor;
    this.mapaService.changeParams(sensor, periodo);
  }

  changeFiltro(filtro_min, filtro_max) {
    this.mapaService.changeFiltro(filtro_min, filtro_max);
  }

  changeChart(res) {
    this.graficoService.changeChart(res);
  }

  changeCachedAt(cached_at) {
    this.callComponentTopo.next(cached_at);
  }

  changePeriodoByTargetCharts(periodo, target) {
    const data = [];
    data[0] = periodo;
    data[1] = target;
    this.callChartPeriodoTarget.next(data);
  }

  changeEstacaoCharts(estacao) {
    this.callChartEstacao.next(estacao);
  }

  getUrl(periodo, sensor = null) {
    this.params.periodo = periodo;
    if (periodo.indexOf('h') != -1) {
      return 'rpc/v1/dado-sensor-grafico?periodo=24h&';
    } else {
      if (periodo.indexOf('d') != -1) {
        if (sensor == '4,2,3' || sensor == '6,7,8') {
          return 'rpc/v1/dado-sensor-diario?';
        } else {
          return 'rpc/v1/dado-sensor-diario?';
        }

      } else {
        if (sensor == '4,2,3' || sensor == '6,7,8') {
          return 'rpc/v1/dado-sensor-diario?periodo=' + periodo + '&';
        } else {
          return 'rpc/v1/dado-sensor-diario?periodo=' + periodo + '&';
        }

      }
    }
  }

}
