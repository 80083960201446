import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalApp } from '../../helpers/global';

@Injectable()
export class RelatorioGraficoService {

  month_names = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ];

  constructor(
    private http: HttpClient,
    private globalApp: GlobalApp
  ) { }

  getMunicipio(mun) {
    return this.http.get(this.globalApp.base_url + 'rest/adm/municipio?id=' + mun.id);
  }

  getInstituicao(id) {
    return this.http.get(this.globalApp.base_url + 'rest/adm/instituicao?id=' + id);
  }

}
