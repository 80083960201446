import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.css']
})
export class ButtonDropdownComponent implements OnInit {

  @Input()
  color = 'default';
  @Input()
  title = 'Button';
  constructor() { }

  ngOnInit() {
  }

}
