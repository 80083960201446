import { Component, OnInit } from '@angular/core';

import { GraficoService } from './../../../services/grafico.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RelatorioGraficoService } from '../relatorio-grafico.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  codigo_estacao: number;
  nome_estacao = '';
  uf_estacao = '';
  codigoOrigem = '';
  instituicao_nome = '';
  municipio_nome = '';

  temp_default = '';
  temp_min = '';
  temp_max = '';

  instituicao;

  isvisibleCharts = true;

  sensores = [];
  sensores_others = [];

  loading = false;

  metadata_radiacao = {
    results: [
      {
        data: {},
        color: '',
        labels: []
      }
    ]
  };

  ult_hrs = [
    {
      id: '24h',
      text: 'Últimas 24 Horas.'
    },

    {
      id: '48h',
      text: 'Últimas 48 Horas.'
    },

    {
      id: '72h',
      text: 'Últimas 72 Horas.'
    },

    {
      id: '96h',
      text: 'Últimas 96 Horas.'
    },
  ];

  ult_hr = '24h';

  ult_dias = [
    {
      id: '30d',
      text: 'Últimos 30 dias.'
    }
  ];

  ult_dia = '30d';

  ult_meses = [
    {
      id: '1a',
      text: 'Últimos 12 meses.'
    },
    {
      id: '2a',
      text: 'Últimos 24 meses.'
    },
    {
      id: '3a',
      text: 'Últimos 36 meses.'
    }
  ];

  ult_mes = '1a';

  constructor(
    private graficoService: GraficoService,
    private router: Router,
    private route: ActivatedRoute,
    private relatorioGraficoService: RelatorioGraficoService,
    public sharedService: SharedService
  ) {
    this.route.params.subscribe((params) => {
      this.codigo_estacao = params.codigo;
      this.graficoService.getEstacao(params.codigo).subscribe((sen) => {
        if (sen['data']['list'][0].sensor) {
          this.sensores = this.graficoService.transfSensores(sen['data']['list'][0].sensor);
          this.sensores_others = this.graficoService.getSensoresOthers(this.sensores);
        }
      });

    });

  }

  ngOnInit() {
    const d = new Date(Date.now());
    d.setDate(1);
    for (let i = 0; i <= 23; i++) {
      this.ult_dias.push(
        {
          id: i + 'm',
          text: this.relatorioGraficoService.month_names[d.getMonth()] + '/' + d.getFullYear(),
        }
      );
      d.setMonth(d.getMonth() - 1);
    }
  }

  reloadUrl() {
    this.router.navigate(['graficos/' + this.codigo_estacao]);
  }

  changeUltHr(e: any) {
    this.ult_hr = e.value;
    this.sharedService.changePeriodoByTargetCharts(e.value, 'hora_chart');
  }

  changeUltMes(e: any) {
    this.ult_mes = e.value;
    this.sharedService.changePeriodoByTargetCharts(e.value, 'mes_chart');
  }

  changeUltDia(e: any) {
    this.ult_dia = e.value;
    this.sharedService.changePeriodoByTargetCharts(e.value, 'dia_chart');

  }

  changeEstacao(e: any) {
    this.codigo_estacao = e.value;
    this.graficoService.getEstacao(e.value).subscribe((sen) => {
      if (sen['data']['list'][0].sensor) {
        this.sensores = this.graficoService.transfSensores(sen['data']['list'][0].sensor);
        this.sensores_others = this.graficoService.getSensoresOthers(this.sensores);
      }
    });
    this.reloadUrl();
    this.sharedService.changeEstacaoCharts(e.value);
  }

}
