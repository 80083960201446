import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { MenuService } from '../../services/menu.service';

declare var $;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  sensorName = 'Precipitação (mm)';
  sensores;
  sensor;
  periodo = '1h';
  horarios;
  dias;
  region = 'B';
  month = '';
  filtro_min = '';
  filtro_max = '';
  filtro_data = 'hj';
  mes1 = {
    'name': null,
    'month': null,
    'year': null
  };
  mes2 = {
    'name': null,
    'month': null,
    'year': null
  };
  mes3 = {
    'name': null,
    'month': null,
    'year': null
  };

  constructor(
    private sharedService: SharedService,
    private menuService: MenuService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.sensores = this.menuService.sensores;
    this.horarios = this.menuService.horarios;
    this.dias = this.menuService.dias;

    this.activatedRoute.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0) {
        this.onChangeData(params);
      }
    });
  }

  ngOnInit() {
    const d = new Date(Date.now());
    this.mes1.month = d.getMonth();
    this.mes1.year = d.getFullYear();
    this.mes1.name = this.menuService.month_names[d.getMonth()];
    d.setMonth(d.getMonth() - 1);
    this.mes2.month = d.getMonth();
    this.mes2.year = d.getFullYear();
    this.mes2.name = this.menuService.month_names[d.getMonth()];
    d.setMonth(d.getMonth() - 1);
    this.mes3.year = d.getFullYear();
    this.mes3.month = d.getMonth();
    this.mes3.name = this.menuService.month_names[d.getMonth()];

    setInterval(() => {
      location.reload();
    }, 900000);


    setTimeout(() => {
      if (localStorage.getItem('filtro_min') || localStorage.getItem('filtro_min')) {
        this.filtro_min = localStorage.getItem('filtro_min');
        this.filtro_max = localStorage.getItem('filtro_max');
        this.filtroChange();
      }
    }, 1000);

  }

  onChangeData(params: Params) {
    this.sensor = params['sensor'] ? params['sensor'] : this.sensor;
    this.periodo = params['periodo'] ? params['periodo'] : this.periodo;
    if (this.periodo.indexOf('h') !== -1) {
      this.filtro_data = 'hj';
    } else {
      if (this.periodo.indexOf('d') !== -1) {
        this.filtro_data = 'd';
      } else {
        if (this.periodo.indexOf('m') !== -1) {
          this.filtro_data = 'm';
        } else {
          alert('Paramêtro Filtro inválido');
        }
      }
    }

    this.sensorName = this.menuService.getSensorName(this.sensor);
    this.mouseEnter();
    this.mouseLeave();
    this.menuService.changeFilters(this.sensor, this.periodo);
  }

  reloadUrl() {
    this.router.navigate([],
      {
        queryParams: {
          sensor: this.sensor ? this.sensor : this.sharedService.sensor,
          periodo: this.periodo ? this.periodo : this.sharedService.periodo,
        }
      });
  }

  changeZoom(region) {
    this.region = region;
    this.sharedService.changeZoom(this.menuService.getZoom(region), this.menuService.getLat(region), this.menuService.getLon(region));
  }

  changeSensor(sensor) {
    const sensor_obj = this.sensores.filter(
      sensor_data => sensor_data.id == sensor);
    for (const sensor_data of this.menuService.sensores) {
      if (sensor_data.id == parseInt(sensor, 10)) {
        sensor_data.display = true;
        break;
      } else {
        sensor_data.display = false;
      }

    }
    this.sensor = sensor;
    this.sensorName = sensor_obj[0].name;
    this.reloadUrl();
  }

  changeDate(periodo, parameter) {
    this.filtro_data = parameter;
    this.periodo = periodo;
    this.reloadUrl();
  }

  filtroChange() {
    localStorage.setItem('filtro_min', this.filtro_min);
    localStorage.setItem('filtro_max', this.filtro_max);
    this.sharedService.changeFiltro(this.filtro_min, this.filtro_max);
  }

  mouseEnter() {
    for (const sensor of this.menuService.sensores) {
      sensor.display = true;
    }
  }

  mouseLeave() {
    for (const sensor of this.menuService.sensores) {
      if (sensor.name == this.sensorName) {
        sensor.display = true;
      } else {
        sensor.display = false;
      }
    }
  }

  changeFiltroData(filtro_data) {
    this.filtro_data = filtro_data;
  }

}
