import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopoComponent } from './topo/topo.component';
import { FooterComponent } from './footer/footer.component';
import { ButtonGroupComponent } from './bootstrap/button-group/button-group.component';
import { ButtonDropdownComponent } from './bootstrap/button-dropdown/button-dropdown.component';
import { ButtonComponent } from './bootstrap/button/button.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [TopoComponent, FooterComponent, ButtonGroupComponent, ButtonDropdownComponent, ButtonComponent],
  exports: [
    TopoComponent,
    FooterComponent,
    ButtonGroupComponent,
    ButtonDropdownComponent,
    ButtonComponent
  ]
})
export class LayoutModule { }
