import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-legenda',
    templateUrl: './legenda.component.html',
    styleUrls: ['./legenda.component.css']
})
export class LegendaComponent implements OnInit {

    @Input() legenda = [];
    @Input() showLegenda = false;

    constructor() { }

    ngOnInit() {
    }

    closeLegenda() {
        this.showLegenda = !this.showLegenda;
    }

}
