import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboChartComponent } from './combo-chart/combo-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { ChartsService } from './charts.service';
import { LineChartArrowComponent } from './line-chart-arrow/line-chart-arrow.component';
import { LoadingModule } from 'ngx-loading';

@NgModule({
    imports: [
        CommonModule,
        LoadingModule
    ],
    declarations: [ComboChartComponent, LineChartComponent, LineChartArrowComponent],
    exports: [ComboChartComponent, LineChartComponent, LineChartArrowComponent],
    providers: [ChartsService]
})
export class ChartsModule { }
