import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from './../charts/charts.module';
import { MenuComponent } from './menu/menu.component';
import { MapaComponent } from './mapa/mapa.component';
import { LayoutModule } from './../layout/layout.module';
import { MapaService } from '../services/mapa.service';
import { SharedService } from '../services/shared.service';
import { MenuService } from '../services/menu.service';
import { GraficoComponent } from './grafico/grafico.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LoadingModule } from 'ngx-loading';
import { GraficoService } from '../services/grafico.service';
import { LegendaComponent } from './legenda/legenda.component';
import { RelatorioGraficoModule } from './relatorio-grafico/relatorio-grafico.module';
import { InmetComponent } from './inmet/inmet.component';
import { Select2Module } from 'ng2-select2';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NgxChartsModule,
    LoadingModule,
    ChartsModule,
    RouterModule,
    RelatorioGraficoModule,
    Select2Module
  ],
  declarations: [
    MenuComponent,
    MapaComponent,
    GraficoComponent,
    LegendaComponent,
    InmetComponent
  ],
  exports: [
    MenuComponent,
    MapaComponent,
    GraficoComponent
  ],
  providers: [
    MapaService,
    SharedService,
    MenuService,
    GraficoService
  ]
})
export class ContentModule { }
