import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UltimaHoraComponent } from './ultima-hora/ultima-hora.component';
import { UltimoDiaComponent } from './ultimo-dia/ultimo-dia.component';
import { UltimoAnoComponent } from './ultimo-ano/ultimo-ano.component';
import { IndexComponent } from './index/index.component';
import { ChartsModule } from './../../charts/charts.module';
import { RelatorioGraficoService } from './relatorio-grafico.service';
import { Select2Module } from 'ng2-select2';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    Select2Module
  ],
  declarations: [UltimaHoraComponent, UltimoDiaComponent, UltimoAnoComponent, IndexComponent],
  exports: [IndexComponent],
  providers: [RelatorioGraficoService]
})
export class RelatorioGraficoModule { }
