import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Chart } from 'chart.js';
import { ChartsService } from './../charts.service';
import { SharedService } from '../../services/shared.service';

declare var $;

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit, OnChanges {

  public chart = [];
  public results = [];
  public divCanvas;
  @Input() codigo_estacao;
  @Input() sensor;
  @Input() target;
  @Input() colors;
  @Input() url;
  loading = false;
  @Input() periodo = '24h';
  estacao;

  constructor(private chartService: ChartsService, private sharedService: SharedService) {
    this.chartService.callComponetGrafico$.subscribe((data) => {
      this.codigo_estacao = data.codigo;
      this.chartService.getData(this.url, this.sensor, data.codigo).subscribe((res) => {
        this.results = this.chartService.formatDataChartJs(res['data']['list']);
        this.updateChart();
      });
    });

    this.sharedService.callComponentGrafico$.subscribe((periodo) => {
      this.periodo = periodo;
      this.url = this.sharedService.getUrl(periodo, this.sensor);
      this.chartService.getData(this.url, this.sensor, this.codigo_estacao).subscribe((res) => {
        this.results = this.chartService.formatDataChartJs(res['data']['list']);
        this.updateChart();
      });
    });

    this.sharedService.callChartPeriodoTarget$.subscribe((data) => {
      if (this.target.indexOf(data[1]) != -1) {
        this.periodo = data[0];
        this.results = [];
        this.chartService.getData(this.url + 'periodo=' + data[0] + '&', this.sensor, this.codigo_estacao).subscribe((res) => {
          this.results = this.chartService.formatDataChartJs(res['data']['list']);
          this.updateChart();
        });
      }
    });
  }

  ngOnInit() {

    this.divCanvas = 'div-' + this.target;
    if (this.url === undefined) {
      this.url = this.sharedService.getUrl(this.sharedService.params.periodo, this.sensor);
    }
    this.chartService.getData(this.url, this.sensor, this.codigo_estacao).subscribe((res) => {
      this.results = this.chartService.formatDataChartJs(res['data']['list']);
      this.draw();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['codigo_estacao'] && this.url !== undefined) {
      this.results = [];
      this.chartService.getData(this.url + 'periodo=' + this.periodo + '&', this.sensor, this.codigo_estacao).subscribe((res) => {
        this.results = this.chartService.formatDataChartJs(res['data']['list']);
        this.updateChart();
      });
    }
  }

  draw() {
    this.chart = new Chart(this.target, {
      type: 'line',
      data: {
        labels: this.chartService.getLabels(this.results),
        datasets: this.chartService.getResults(this.results, this.colors)
      },
      options: {
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            display: true,
            type: 'time',
          }],
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: false
            }
          }],
        },
        spanGaps: false,
        tooltips: {
          callbacks: {
            title: () => {
              return '';
            },
            beforeLabel: (tooltipItem, data) => {
              const dt = new Date(tooltipItem.xLabel);
              return ('00' + dt.getDate().toString()).slice(-2) + '/' + ('00' + (dt.getMonth() + 1).toString()).slice(-2) +
                '/' + dt.getFullYear() + ' ' + ('00' + dt.getHours().toString()).slice(-2) + ':' +
                ('00' + dt.getMinutes().toString()).slice(-2);
            }
          }
        },
        zoom: {
          // Boolean to enable zooming
          enabled: true,

          // Enable drag-to-zoom behavior
          drag: true,

          // Zooming directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow zooming in the y direction
          mode: 'xy',
          rangeMin: {
            // Format of min zoom range depends on scale type
            x: null,
            y: null
          },
          rangeMax: {
            // Format of max zoom range depends on scale type
            x: null,
            y: null
          }
        }
      }
    });
  }

  updateChart() {
    $('#' + this.target).remove();
    const reloadCanvas = $('<canvas/>', {
      id: this.target
    });
    $('#div-' + this.target).append(reloadCanvas);
    this.draw();
  }

}
