import { Component, OnInit, Input } from '@angular/core'; 

@Component({
    selector: 'app-ultimo-ano',
    templateUrl: './ultimo-ano.component.html',
    styleUrls: ['./ultimo-ano.component.css']
})
export class UltimoAnoComponent implements OnInit {

    @Input() sensores;
    @Input() sensores_others;
    @Input() codigo_estacao;
    @Input() instituicao;
    @Input() periodo;
    
    constructor() { }

    ngOnInit() {
    }

}
