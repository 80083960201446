import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: 'app-ultima-hora',
  templateUrl: './ultima-hora.component.html',
  styleUrls: ['./ultima-hora.component.css']
})
export class UltimaHoraComponent implements OnInit {

  @Input() sensores;
  @Input() sensores_others;
  @Input() codigo_estacao;
  @Input() instituicao;
  @Input() periodo;

  constructor() { }

  ngOnInit() {
  }

}
