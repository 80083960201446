import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import * as moment from 'moment';
import { GlobalApp } from '../helpers/global';

@Injectable()
export class GraficoService {

  private sensores = {
    '22': 'Precipitação(mm)',
    '222': 'Precipitação Acumulada(mm)',
    '2': 'Temperatura a 2m(ºC)',
    '3': 'Temp. Max a 2m(ºC)',
    '4': 'Temp. Min a 2m(ºC)',
    '6': 'UR a 2m(%)',
    '7': 'UR Máx a 2m(%)',
    '8': 'UR Min a 2m(%)',
    '14': 'Pressão (hPa)',
    '15': 'Pressão Max (hPa)',
    '16': 'Pressão Min (hPa)',
    '18': 'velocidade do Vento a 2m (m/s)',
    '20': 'Rajada a 2m(m/s)',
    '21': 'Radiação (kJ/m²)',
    '19': 'Direção a 2m(º)',
    '50': 'Velocidade Vento 10m (m/s)',
    '51': 'Direção 10m (º)',
    '30': 'Velocidade Máx 10m (m/s)'
  };

  private variaveis = {
    '22': '(mm)',
    '222': '(mm)',
    '2': '(ºC)',
    '3': '(ºC)',
    '4': '(ºC)',
    '6': '(%)',
    '7': '(%)',
    '8': '(%)',
    '14': '(hPa)',
    '15': '(hPa)',
    '16': '(hPa)',
    '18': '(m/s)',
    '20': '(m/s)',
    '21': '(kJ/m²)',
    '19': '(º)',
    '50': '(m/s)',
    '51': '(º)',
    '30': '(m/s)'
  };



  private callComponentGrafico = new Subject<any>();
  callComponetGrafico$ = this.callComponentGrafico.asObservable();


  constructor(private http: HttpClient, private globalApp: GlobalApp) { }

  getData(sensor, estacao) {
    return this.http.get(this.globalApp.base_url + 'rpc/v1/dado-sensor-grafico?sensor=' + sensor + '&estacao=' + estacao);
  }

  getEstacao(estacao) {
    return this.http.get(this.globalApp.base_url + 'rest/pcd/estacao?id=' + estacao + '&with=sensor');
  }

  getMunicipio(id) {
    return this.http.get(this.globalApp.base_url + 'rest/adm/municipio?id=' + id);
  }

  getSensor(id) {
    return this.sensores[id];
  }

  getVariavel(id) {
    return this.variaveis[id];
  }

  changeChart(res) {
    this.callComponentGrafico.next(res);
  }

  formatDataChartJs(res) {
    let obj = {
      'labels': [],
      'data': [],
      'label': ''
    };
    if (res.length >= 0) {
      const data_response = [];

      for (const re of res) {
        let _obj = {
          'labels': [],
          'data': [],
          'label': ''
        };
        _obj.label = this.sensores[re.name];
        for (const r of re.series) {
          const dt = new Date(r.name);
          dt.setHours(dt.getHours() - 3);
          _obj.data.push(
            {
              'x': dt,
              'y': r.value
            }
          );

          _obj.labels.push(r.name);

        }
        data_response.push(_obj);
      }

      return data_response;

    } else {
      const data_response = [obj];
      for (const r of res.series) {

        const dt = new Date(r.name);
        dt.setHours(dt.getHours() - 3);
        data_response[0].data.push(
          {
            'x': dt,
            'y': r.value
          }
        );
        data_response[0].labels.push(dt);
        data_response[0].label = this.sensores[res.name];
      }
      return data_response;
    }
  }

  getLabels(data) {
    let labels;
    for (const d of data) {
      labels = d.labels;
    }

    return labels;
  }

  getResults(results, colors, combo_chart = false) {
    const result = [];
    results.forEach((item, index) => {
      result.push(
        {
          data: item.data,
          borderColor: colors[index],
          fill: false,
          label: item.label,
          type: (index == 0 && combo_chart) ? 'bar' : 'line',
          backgroundColor: colors[index],
        }
      );
    });
    return result;
  }

  getResultsArrow(results, colors, combo_chart = false) {
    const result = [];
    results.forEach((item, index) => {
      const styles = this.getPointStyle(item.data);
      result.push(
        {
          data: item.data,
          borderColor: colors[index],
          fill: false,
          label: item.label,
          type: (index == 1 && combo_chart) ? 'line' : null,
          backgroundColor: colors[index],
          pointStyle: (styles.length > 2) ? styles : 'circle',
        }
      );
    });
    return result;
  }

  formatDataChartJsArrow(res) {
    let obj = {
      'labels': [],
      'data': [],
      'label': ''
    };
    if (res.length >= 0) {
      const data_response = [];

      res.forEach((re, index) => {

        let _obj = {
          'labels': [],
          'data': [],
          'label': ''
        };
        _obj.label = this.sensores[re.name];
        for (const r of re.series) {
          const dt = new Date(r.name);
          dt.setHours(dt.getHours() - 3);
          _obj.data.push(
            {
              'x': dt,
              'y': re.name == 19 ? 5 : r.value,
              'data': r.value,
              'sensor': re.name,
            }
          );

          _obj.labels.push(r.name);

        }
        data_response.push(_obj);
      });

      return data_response;

    }
  }

  getPointStyle(data) {
    let styles = [];
    for (const d of data) {
      if (d.sensor == 19 || d.data === null) {
        if (d.data === null) {
          styles.push('circle');
        } else {
          const img = new Image();
          img.src = d.data;
          styles.push(img);
        }
      }

    }
    return styles;
  }

  transfSensores(sensores) {
    let _sensores = [];
    for (let sensor of sensores) {
      _sensores.push(parseInt(sensor.id, 10));
    }
    return _sensores;
  }

  getSensoresOthers(sensores) {
    let sensores_ = [];
    sensores.forEach(element => {
      sensores_.push(element);
    });
    sensores_.splice(sensores_.indexOf(2), 1);
    sensores_.splice(sensores_.indexOf(3), 1);
    sensores_.splice(sensores_.indexOf(4), 1);
    sensores_.splice(sensores_.indexOf(22), 1);
    sensores_.splice(sensores_.indexOf(18), 1);
    sensores_.splice(sensores_.indexOf(19), 1);
    sensores_.splice(sensores_.indexOf(20), 1);
    sensores_.splice(sensores_.indexOf(50), 1);
    sensores_.splice(sensores_.indexOf(30), 1);
    sensores_.splice(sensores_.indexOf(51), 1);

    return sensores_;
  }

}
