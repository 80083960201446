import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { ContentModule } from './content/content.module';
import { GlobalApp } from './helpers/global';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AppLoadService } from './services/app-load.service';
import { IndexComponent } from './content/relatorio-grafico/index/index.component';
import { InmetComponent } from './content/inmet/inmet.component';

const routes: Routes = [

  { path: '', component: InmetComponent },
  { path: 'graficos',
    children: [
      { path: '', component: IndexComponent },
      { path: ':codigo', component: IndexComponent }
    ]
  },
  

];


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    ContentModule,
    RouterModule.forRoot(routes)
  ],
  providers: [
    GlobalApp,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    // AppLoadService,
    // {
    //   provide: APP_INITIALIZER,
    //   deps: [AppLoadService],
    //   useFactory(appLoadService: AppLoadService) {
    //     return () => appLoadService.initializeApp();
    //   },
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
