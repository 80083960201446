import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';

@Injectable()
export class MenuService {

  dados = {
    'B': {
      'zoom': 5,
      'lat': -17.2131,
      'lon': -53.0220
    },
    'N': {
      'zoom': 6,
      'lat': -6.5,
      'lon': -60
    },
    'NE': {
      'zoom': 6,
      'lat': -10,
      'lon': -42
    },
    'CO': {
      'zoom': 6,
      'lat': -14.6,
      'lon': -55.3
    },
    'SE': {
      'zoom': 6,
      'lat': -20.4,
      'lon': -46
    },
    'S': {
      'zoom': 6,
      'lat': -27.2,
      'lon': -51.8
    },

  };

  sensores = [
    {
      'id': 22,
      'name': 'Precipitação (mm)',
      'display': true
    },
    {
      'id': 2,
      'name': 'Temperatura (°C)',
      'display': false
    },
    {
      'id': 3,
      'name': 'Temperatura Máx (°C)',
      'display': false
    },
    {
      'id': 4,
      'name': 'Temperatura Mín (°C)',
      'display': false
    },
    {
      'id': 14,
      'name': 'Pressão (hPa)',
      'display': false
    },
    {
      'id': 15,
      'name': 'Pressão Máx (hPa)',
      'display': false
    },
    {
      'id': 16,
      'name': 'Pressão Mín (hPa)',
      'display': false
    },
    {
      'id': 21,
      'name': 'Radiação (kJ/m²)',
      'display': false
    },
    {
      'id': 6,
      'name': 'UR Relativa (%)',
      'display': false
    },
    {
      'id': 7,
      'name': 'UR Relativa Máx (%)',
      'display': false
    },
    {
      'id': 8,
      'name': 'UR Relativa Mín (%)',
      'display': false
    },
    {
      'id': 18,
      'name': 'Vento (m/s)',
      'display': false
    },
    {
      'id': 20,
      'name': 'Vento Rajada (m/s)',
      'display': false
    },
    {
      'id': 0,
      'name': 'Raios',
      'display': false
    }
  ];

  horarios = [
    {
      'id': '1h',
      'name': '1h'
    },
    {
      'id': '6h',
      'name': '6h'
    },
    {
      'id': '12h',
      'name': '12h'
    },
    {
      'id': '24h',
      'name': '24h'
    },
    {
      'id': '48h',
      'name': '48h'
    },
    {
      'id': '72h',
      'name': '72h'
    },
    {
      'id': '96h',
      'name': '96h'
    }
  ];

  dias = [
    {
      'id': '0d',
      'name': 'Hoje'
    },
    {
      'id': '1d',
      'name': 'Ontem'
    },
    {
      'id': '7d',
      'name': '7 dias'
    },
    {
      'id': '15d',
      'name': '15 dias'
    }
  ];

  month_names = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
    'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
  ];

  constructor(private sharedService: SharedService) { }

  getZoom(region) {
    return this.dados[region].zoom;
  }

  getLat(region) {
    return this.dados[region].lat;
  }

  getLon(region) {
    return this.dados[region].lon;
  }

  getSensorName(sensor) {
    const name = this.sensores.filter((element) => {
      if (element.id == sensor) {
        return element.name;
      }
    });

    return name[0].name;
  }

  changeFilters(sensor, periodo) {
    this.sharedService.changeFilters(sensor, periodo);
  }

}
