import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import OlSourceVector from 'ol/source/vector';
import OlFormatGeoJson from 'ol/format/geojson';
import OlLayerVector from 'ol/layer/vector';
import OlProj from 'ol/proj';
import OlControl from 'ol/control';
import OlControlMousePosition from 'ol/control/mouseposition';
import OlCoordinate from 'ol/coordinate';
import { GlobalApp } from '../helpers/global';

@Injectable()
export class MapaService {

  vectorLayer: any;
  vectorSource: any;
  canvas = document.createElement('canvas');
  context: any;
  mousePositionControl: any;
  public latMap = -17.2131;
  public lonMap = -53.0220;
  public zoom = 5;
  public data_layer;
  sensor;
  periodo;
  dataFeatures;
  private componentMethodCallSource = new Subject<any>();
  componentMethodCalled$ = this.componentMethodCallSource.asObservable();
  private componentMethodCallSensor = new Subject<any>();
  componentMethodCalledSensor$ = this.componentMethodCallSensor.asObservable();
  private componentMethodCallDate = new Subject<any>();
  componentMethodCalledDate$ = this.componentMethodCallDate.asObservable();
  private componentMethodCallFilter = new Subject<any>();
  componentMethodCalledFilter$ = this.componentMethodCallFilter.asObservable();

  public legendas = [
    {
      22: [
        {
          'text': 'Sem chuva (0.0 mm)',
          'color': '#FFFFFF'
        },
        {
          'text': 'De 0.1 a 5.0 mm',
          'color': '#F43839'
        },
        {
          'text': 'De 5.1 a 15.0 mm',
          'color': '#E6DF40'
        },
        {
          'text': 'De 15.1 a 25.0 mm',
          'color': '#2DE033'
        },
        {
          'text': 'De 25.1 a 50.0 mm',
          'color': '#1C98FC'
        },
        {
          'text': 'Acima de 50.0 mm',
          'color': '#7800D9'
        },
      ],
      2: [
        {
          'text': '< 10º',
          'color': '#0000F5'
        },
        {
          'text': '>= 10º  < 15º',
          'color': '#1C98FC'
        },
        {
          'text': '>= 15º  < 20º',
          'color': '#87CEEB'
        },
        {
          'text': '>= 20º  < 25º',
          'color': '#E6DF40'
        },
        {
          'text': '>= 25º  < 30º',
          'color': '#F8A220'
        },
        {
          'text': '>= 30º  < 35º',
          'color': '#F43839'
        },
        {
          'text': '>= 35º',
          'color': '#A72730'
        }
      ],
      20: [
        {
          'text': '<0,3	m/s',
          'color': '#FFFFFF'
        },
        {
          'text': '0,3 a 1,5 m/s',
          'color': '#CCFFFF'
        },
        {
          'text': '1,6 a 3,3 m/s',
          'color': '#9CFFFF'
        },
        {
          'text': '3,4 a 5,4 m/s',
          'color': '#6EFFFF'
        },
        {
          'text': '5,5 a 7,9 m/s',
          'color': '#32FFFF'
        },
        {
          'text': '8 a 10,7 m/s',
          'color': '#27CAFE'
        },
        {
          'text': '10,8 a 13,8 m/s',
          'color': '#1B95FC'
        },
        {
          'text': '13,9 a 17,1 m/s',
          'color': '#0E60FB'
        },
        {
          'text': '17,2 a 20,7 m/s',
          'color': '#0000FB'
        },
        {
          'text': '20,8 a 24,4 m/s',
          'color': '#0000C7'
        },
        {
          'text': '24,5 a 28,4 m/s',
          'color': '#000095'
        },
        {
          'text': '28,5 a 32,6 m/s',
          'color': '#000064'
        },
        {
          'text': '>32,7 m/s',
          'color': '#000000'
        }
      ],

      6: [
        {
          'text': '< 20%',
          'color': '#FC0000'
        },
        {
          'text': '20% a 30%',
          'color': '#FC7715'
        },
        {
          'text': '30% a 40%',
          'color': '#FBC92A'
        },
        {
          'text': '40% a 50%',
          'color': '#FEFF6F'
        },
        {
          'text': '50% a 60%',
          'color': '#2CDD5D'
        },
        {
          'text': '60% a 70%',
          'color': '#2CDDB2'
        },
        {
          'text': '70% a 75%',
          'color': '#28C6C5'
        },
        {
          'text': '75% a 80%',
          'color': '#32FFFE'
        },
        {
          'text': '80% a 85%',
          'color': '#2BDAFD'
        },
        {
          'text': '85% a 90%',
          'color': '#1B91FC'
        },
        {
          'text': '90% a 95%',
          'color': '#1700F9'
        },
        {
          'text': '> 95%',
          'color': '#1900B0'
        }
      ],
    }

  ];

  constructor(
    private http: HttpClient,
    private globalApp: GlobalApp
  ) {
    this.mousePositionControl = new OlControlMousePosition({
      coordinateFormat: OlCoordinate.createStringXY(4),
      projection: 'EPSG:4326',
      undefinedHTML: '&nbsp;'
    });

  }

  setUrlLayer(params = null) {
    const sensor = params['sensor'] ? params['sensor'] : this.sensor;
    const periodo = params['periodo'] ? params['periodo'] : this.periodo;
    return this.http.get(this.globalApp.base_url + 'rpc/v2/dado-sensor?instituicao=41' +
      '&sensor=' + sensor + '&periodo=' + periodo);
  }

  changeZoom(zoom, lat, lon) {
    const data = [];
    data.push(zoom);
    data.push(lat);
    data.push(lon);
    this.componentMethodCallSource.next(data);
  }

  changeParams(sensor, periodo) {
    this.sensor = sensor;
    const date = [sensor, periodo];
    this.componentMethodCallDate.next(date);
  }

  generateObjectFeature(data) {

    let data_geom = {
      'type': null,
      'features': null,
      'crs': {
        'properties': {
          'name': 'EPSG:4326'
        },
        'type': 'name'
      }
    };
    let data_search;
    data_geom.type = 'FeatureCollection';
    data_geom.features = [];
    if (data['data']['list'] != null) {
      data_search = data['data']['list'];
      this.dataFeatures = data;
    } else {
      data_search = data['data']['list'];
    }

    for (let row of data_search) {
      let feature = {
        'type': null,
        'properties': {
          'codigo': null,
          'codigoOrigem': null,
          'valor': null,
          'sensor': null,
          'nome': null,
          'latitude': null,
          'longitude': null,
          'uf': null,
        },
        'geometry': {
          'type': null,
          'coordinates': null,
        },
      };
      feature.type = 'Feature';
      feature.properties.codigo = row.codigo;
      feature.properties.codigoOrigem = row.codigo_origem;
      feature.properties.nome = row.nome;
      feature.properties.valor = row.valor;
      feature.properties.sensor = row.sensor;
      feature.properties.latitude = row.geom.lat;
      feature.properties.longitude = row.geom.lon;
      feature.properties.uf = row.municipio;
      feature.geometry.type = 'Point';
      feature.geometry.coordinates = OlProj.fromLonLat([parseFloat(row.geom.lon), parseFloat(row.geom.lat)]);
      data_geom.features.push(feature);
    }

    return data_geom;
  }

  changeFiltro(filtro_min, filtro_max) {
    let data_filtered = {
      data: {
        list: null
      }
    };
    if (filtro_min === '') {
      filtro_min = 0;
    }
    if (filtro_max === '') {
      filtro_max = 0;
    }
    filtro_min = parseFloat(filtro_min);
    filtro_max = parseFloat(filtro_max);

    if (filtro_max == 0 && filtro_min == 0) {
      console.log(this.dataFeatures['data']['list']);
      data_filtered.data.list  = this.dataFeatures['data']['list'];
    } else if (filtro_min > filtro_max) {
      data_filtered['data']['list'] = this.dataFeatures['data']['list'].filter((row) => {
        return (row.valor >= filtro_min);
      });
    } else {
      data_filtered.data.list = this.dataFeatures['data']['list'].filter((row) => {
        return (row.valor >= filtro_min && row.valor <= filtro_max);
      });
    }

    this.componentMethodCallFilter.next(data_filtered);
  }

}
