import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.getToken()}`
      }
    });
    return next.handle(request);
  }

  getToken() {
    return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjIwZjk4MmRhNzY3NGRiM2QzMjI1MGJhNGU3ZmE0MzBiMjRjNTNjZGYwNmIxYzY0MDVlOTE2ZDI0YzYxZTQ4ZTdhY2RlMzU2YmU5MDQzZGJjIn0.eyJhdWQiOiI0NyIsImp0aSI6IjIwZjk4MmRhNzY3NGRiM2QzMjI1MGJhNGU3ZmE0MzBiMjRjNTNjZGYwNmIxYzY0MDVlOTE2ZDI0YzYxZTQ4ZTdhY2RlMzU2YmU5MDQzZGJjIiwiaWF0IjoxNTc1MDM1NTk4LCJuYmYiOjE1NzUwMzU1OTgsImV4cCI6MTYwNjU3MTU5OCwic3ViIjoiNDciLCJzY29wZXMiOlsiKiJdfQ.YvCKS4kTreWMA2sbl-B_KjoNk67XcX9uISQjfEdR0OI6EYHOTFouSTJ4GsmnrQmd3qlSgJpaOOxO9SPcCD61hQsKRmPNFR0boZprlBoBFlMMVpsk-0SI_KqDGoWOosUqWjbp6zb9-_tCpd8hMQ7r_7SoaColHMiP_7KM6G87ckqOaFuODX-H8l6-rOuToIgSSd5bm1uxkY8r_K-EAfUhGI4moBTbkN-4aa0f13wU_n1IMTJSH78ovvZdmDJgQDZr1qzE_RnnWXOaWBilIUumTevT7sETtCcJtncGQMhzyTPRwshbRzR5TlzZ9lAboPxMInABCn0xXF70dV4N-2nQIFXOYWE8YymFUfUGCs0agOkbrLfIsCYsT0G1t1NXCP7gBrb7Jvjp0yooB_jsbkfiw4oIrYsCPyz3hxk3DgdsHxgoB0ALn7SgZYeEICebXNgZq4dop5gtNhxMp8cqkidLdb6IlmgbTOscQ1dCsOVeTGRDMwJI2wyLTIPhNBbnh2ngYhRTQl64vk5sKAOLn1Db-n31DadRCnZ5QWdhWW09WIjneM5cnwAoetUi4Bz2zvIHVr814pBpBtWf-G-qS4S8nl4Wik_nokPiloC3p-btorTQ6tRdEWMeNBuXRIn_GBgUdnS9hRyuPrfRIxB8Wx0zDkjhnZoIn6OuMlBKNm7bncU';
  }
}
