import { Component, OnInit } from '@angular/core';

import OlMap from 'ol/map';
import OlLayerTile from 'ol/layer/tile';
import OlSourceOSM from 'ol/source/osm';
import OlSourceXYZ from 'ol/source/xyz';
import OlView from 'ol/view';
import OlControl from 'ol/control';
import OlProj from 'ol/proj';
import OlStyleIcon from 'ol/style/icon';
import OlSourceVector from 'ol/source/vector';
import OlFormatGeoJson from 'ol/format/geojson';
import OlLayerVector from 'ol/layer/vector';
import OlStyleStyle from 'ol/style/style';
import OlStyleStroke from 'ol/style/stroke';
import OlStyleFill from 'ol/style/fill';
import OlStyleText from 'ol/style/text';
import OlInteractionSelect from 'ol/interaction/select';
import OlEventsCondition from 'ol/events/condition';
import { MapaService } from '../../services/mapa.service';
import OlOverlay from 'ol/overlay';
import { SharedService } from '../../services/shared.service';
import { GraficoService } from '../../services/grafico.service';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit {

  protected map;
  selectedFeatures: any;
  latMap = -22.8531;
  lonMap = -43.0220;
  zoom = 5;
  vectorLayer: any;
  vectorSource: any;
  data_layer: Object;
  legenda = [];
  showLegenda = false;
  public loading = false;
  dataInfo;
  showInfo = false;
  highlight;
  container: any;
  content: any;
  closer: any;
  overlay: any;
  featureOverlay = new OlLayerVector({
    source: new OlSourceVector(),
    map: this.map,
  });
  constructor(
    private mapaService: MapaService,
    private sharedService: SharedService,
    private graficoService: GraficoService) {
    this.mapaService.componentMethodCalled$.subscribe(
      (data) => {
        this.changeZoom(data);
      }
    );

    this.mapaService.componentMethodCalledDate$.subscribe(
      (date) => {
        this.changeParams(date);
      }
    );

    this.mapaService.componentMethodCalledFilter$.subscribe(
      (date) => {
        this.changeFilter(date);
      }
    );

    this.getData([]);

  }

  ngOnInit() {

    this.container = document.getElementById('popup');
    this.content = document.getElementById('popup-content');
    this.closer = document.getElementById('popup-closer');
    this.overlay = new OlOverlay( /** @type {olx.OverlayOptions} */({
      element: this.container,
      autoPan: true

    }));

    this.map = new OlMap({
      target: 'map',
      controls: OlControl.defaults({
        attributionOptions: {
          collapsible: false
        }
      }).extend([this.mapaService.mousePositionControl]),
      layers: [
        new OlLayerTile({
          source: new OlSourceXYZ({
            url: 'http://tiles.funceme.br/styles/klokantech-basic/{z}/{x}/{y}.png'
          })
        })
      ],
      overlays: [this.overlay],
      view: new OlView({
        center: OlProj.fromLonLat([this.lonMap, this.latMap]),
        zoom: this.zoom
      })
    });

    const selectClick = new OlInteractionSelect({
      condition: OlEventsCondition.click,
    });

    this.map.addInteraction(selectClick);

    selectClick.on('select', (e) => {
      if (e.target.getFeatures().getArray()[0]) {
        this.sharedService.changeChart(e.target.getFeatures().getArray()[0].getProperties());
      }
    });

    this.map.on('pointermove', (evt) => {
      if (evt.dragging) {
        return;
      }
      let pixel = this.map.getEventPixel(evt.originalEvent);
      this.displayFeatureInfo(pixel, evt.coordinate);
    });

    const pointrmove = new OlInteractionSelect({
      condition: OlEventsCondition.pointerMove
    });

    this.map.addInteraction(pointrmove);

    this.selectedFeatures = pointrmove.getFeatures();


    this.legenda = this.mapaService.legendas[0]['22'];
    this.showLegenda = true;

    if (localStorage.getItem('zoom_pcd_inmet') && localStorage.getItem('center_pcd_inmet')) {
      let dt_zoom = [];
      const dt_center = localStorage.getItem('center_pcd_inmet').split(',');
      dt_zoom[0] = localStorage.getItem('zoom_pcd_inmet');
      dt_zoom[1] = parseFloat(dt_center[1]);
      dt_zoom[2] = parseFloat(dt_center[0]);

      this.changeZoomInit(dt_zoom);
    }

    this.map.on('moveend', () => {
      const zoom = this.map.getView().getZoom();
      const center = this.map.getView().getCenter();
      localStorage.setItem('zoom_pcd_inmet', zoom);
      localStorage.setItem('center_pcd_inmet', center);
    });

  }

  changeZoom(data) {
    this.map.getView().setZoom(data[0]);
    this.map.getView().setCenter(OlProj.fromLonLat([data[2], data[1]]));
  }

  changeZoomInit(data) {
    this.map.getView().setZoom(data[0]);
    this.map.getView().setCenter([data[2], data[1]]);
  }

  removeLayer() {
    this.map.getLayers().forEach((lyr) => {
      if ('dataLayer' === lyr.get('id')) {
        lyr.getSource().clear();
      }
    });
  }

  changeLayer() {

    const vectorSource = new OlSourceVector({
      features: (new OlFormatGeoJson()).readFeatures(this.mapaService.generateObjectFeature(this.data_layer))
    });

    const vectorLayer = new OlLayerVector({
      source: vectorSource,
      style: null,
      id: 'dataLayer',
    });

    this.setStyle(vectorLayer.getSource().getFeatures());

    this.map.addLayer(vectorLayer);
  }


  changeParams(data) {
    const params = [];
    params['sensor'] = data[0];
    params['periodo'] = data[1];
    this.getData(params);
  }

  setStyle(features) {

    for (const feature of features) {
      let numb = feature.get('valor');
      numb = (numb == null) ? 'SI' : parseFloat(numb).toFixed(1);
      const pointStyle = {
        crossOrigin: 'anonymous',
        src: 'assets/images/btn_white.jpg',
        size: this.getSizeImg(parseFloat(numb).toFixed(1).toString()),
        color: this.getColor(feature.get('sensor'), numb)
      };

      const style = new OlStyleStyle({
        text: new OlStyleText({
          font: '11px Calibri,sans-serif',
          fill: new OlStyleFill({ color: this.getTextColor(feature.get('sensor'), feature.get('valor')) }),
          stroke: new OlStyleStroke({
            color: this.getTextColor(feature.get('sensor'), feature.get('valor')), width: 0.5
          }),
          text: numb,
        }),
        image: new OlStyleIcon(
          pointStyle,
        )
      });
      feature.setStyle(style);
    }
  }

  getSizeImg(value) {
    let w = 30;
    if (value.length < 4) {
      w = 30;
    } else if (value.length <= 7) {
      w = 48;
    } else if (value.length >= 8) {
      w = 50;
    }
    return [w, 20];
  }

  getColor(sensor, valor) {
    if (sensor == null) {
      return '#DCDCDC';
    }
    if (sensor == '22') {
      return this.getPrecipColor(valor);
    } else if (sensor == '2' || sensor == '3' || sensor == '4') {
      return this.getTempColor(valor);
    } else if (sensor == '20') {
      return this.getRajadaColor(valor);
    } else if (sensor == '6' || sensor == '7' || sensor == '8') {
      return this.getUmidadeColor(valor);
    } else {
      return '#696969';
    }

  }

  getTextColor(sensor, valor) {
    if (sensor == '22') {
      return this.getPrecipTextColor(valor);
    } else {
      return '#000';
    }
  }

  getPrecipColor(valor) {

    let color = '#DCDCDC';

    if (valor == -999.0) {
      return color;
    }

    if (valor == 0.0) {
      color = '#000';
    } else if (valor >= 0.1 && valor < 5.0) {
      color = '#F43839';
    } else if (valor >= 5.0 && valor < 15.0) {
      color = '#E6DF40';
    } else if (valor >= 15.0 && valor < 25.0) {
      color = '#2DE033';
    } else if (valor >= 25.0 && valor <= 50.0) {
      color = '#1C98FC';
    } else if (valor >= 50.1) {
      color = '#7800D9';
    } else {
      return '#696969';
    }

    return color;
  }

  getPrecipTextColor(valor) {

    let color = '#000';

    if (valor == 0.0 || valor == -999.00) {
      color = '#FFF';
    }

    return color;
  }

  getTempColor(valor) {

    let color = '#DCDCDC';

    if (valor == -999.0) {
      return color;
    }

    if (valor < 10.0) {
      color = '#0000F5';
    } else if (valor >= 10.0 && valor < 15.0) {
      color = '#1C98FC';
    } else if (valor >= 15.0 && valor < 20.0) {
      color = '#87CEEB';
    } else if (valor >= 20.0 && valor < 25.0) {
      color = '#E6DF40';
    } else if (valor >= 25.0 && valor < 30.0) {
      color = '#F8A220';
    } else if (valor >= 30.0 && valor < 35.0) {
      color = '#F43839';
    } else if (valor >= 35.0) {
      color = '#A72730';
    }

    return color;
  }

  getRajadaColor(valor) {

    let color = '#DCDCDC';

    if (valor == -999.0) {
      return color;
    }

    if (valor < 0.3) {
      color = '#FFFFFF';
    } else if (valor >= 0.3 && valor <= 1.5) {
      color = '#CCFFFF';
    } else if (valor >= 1.6 && valor <= 3.33) {
      color = '#9CFFFF';
    } else if (valor >= 3.4 && valor <= 5.4) {
      color = '#6EFFFF';
    } else if (valor >= 5.5 && valor <= 7.9) {
      color = '#32FFFF';
    } else if (valor >= 8.0 && valor <= 10.7) {
      color = '#27CAFE';
    } else if (valor >= 10.8 && valor <= 13.8) {
      color = '#1B95FC';
    } else if (valor >= 13.9 && valor <= 17.1) {
      color = '#0E60FB';
    } else if (valor >= 17.2 && valor <= 20.7) {
      color = '#0000FB';
    } else if (valor >= 20.8 && valor <= 24.4) {
      color = '#0000C7';
    } else if (valor >= 24.5 && valor <= 28.4) {
      color = '#000095';
    } else if (valor >= 28.5 && valor <= 32.6) {
      color = '#000064';
    } else if (valor >= 32.7) {
      color = '#000000';
    }

    return color;
  }

  getUmidadeColor(valor) {

    let color = '#DCDCDC';

    if (valor == -999.0) {
      return color;
    }

    if (valor < 20) {
      color = '#FC0000';
    } else if (valor >= 20 && valor < 30) {
      color = '#FC7715';
    } else if (valor >= 30 && valor < 40) {
      color = '#FBC92A';
    } else if (valor >= 40 && valor < 50) {
      color = '#FEFF6F';
    } else if (valor >= 50 && valor < 60) {
      color = '#2CDD5D';
    } else if (valor >= 60 && valor < 70) {
      color = '#2CDDB2';
    } else if (valor >= 70 && valor < 75) {
      color = '#28C6C5';
    } else if (valor >= 75 && valor < 80) {
      color = '#32FFFE';
    } else if (valor >= 80 && valor < 85) {
      color = '#2BDAFD';
    } else if (valor >= 85 && valor < 90) {
      color = '#1B91FC';
    } else if (valor >= 90 && valor < 95) {
      color = '#1700F9';
    } else if (valor >= 95) {
      color = '#1900B0';
    }

    return color;
  }

  changeFilter(data) {
    this.removeLayer();
    this.data_layer = data;
    this.changeLayer();
  }

  getData(params) {
    this.loading = true;

    this.mapaService.setUrlLayer(params).subscribe(
      (data) => {
        this.changeLegenda(data['data']['list'][0].sensor);
        this.data_layer = data;
        this.removeLayer();
        this.changeLayer();
        this.changeCachedAt(data['meta']['cache']['cached_at']['date']);
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        console.log(err);
        alert('Por Favor, tente novamente mais tarde. Operação excedeu o tempo limite.');
      }
    );
  }

  changeLegenda(sensor) {
    if (sensor == '22' || sensor == '2' || sensor == '3' || sensor == '4' || sensor == '20' || sensor == '6' || sensor == '7' || sensor == '8') {
      if (sensor == '22') {
        this.legenda = this.mapaService.legendas[0]['22'];
      } else if (sensor == '20') {
        this.legenda = this.mapaService.legendas[0]['20'];
      } else if (sensor == '2') {
        this.legenda = this.mapaService.legendas[0]['2'];
      } else {
        this.legenda = this.mapaService.legendas[0]['6'];
      }
      this.showLegenda = true;
    } else {
      this.legenda = [];
      this.showLegenda = false;
    }
  }

  displayFeatureInfo(pixel, coordinate) {
    const feature = this.map.forEachFeatureAtPixel(pixel, (data) => {
      return data;
    });
    const names = this.selectedFeatures.getArray().map((data) => {
      if (data.get('nome') !== undefined) {
        const info = '<b>' + data.get('nome') + '(' + data.get('uf') + ') - ' + data.get('codigoOrigem') + '</b>'
        + '<br>'
        + 'Lat: ' + this.convertLatLon(data.get('latitude'))
        + '<br>'
        + 'Lon: ' + this.convertLatLon(data.get('longitude'))
        + '<br>'
        + this.getSensor(data.get('sensor')) + '<b>' + this.formatValue(data.get('valor')) + '</b>';
        return info;
      }
    });
    if (names.length > 0) {
      this.content.innerHTML = names.join(', ');
      this.overlay.setPosition(coordinate);
    } else {
      this.overlay.setPosition(undefined);
      this.content.innerHTML = 'Nenhuma Estação Selecionada';
    }

    if (feature !== this.highlight) {
      if (this.highlight) {
        this.featureOverlay.getSource().removeFeature(this.highlight);
      }

      if (feature) {
        this.featureOverlay.getSource().addFeature(feature);
      }
      this.highlight = feature;
    }
  }

  changeCachedAt(cached_at) {
    this.sharedService.changeCachedAt(cached_at);
  }

  convertLatLon(value) {

    const convertLat = Math.abs(value);
    const LatDeg = Math.floor(convertLat);
    const LatMin = (Math.floor((convertLat - LatDeg) * 60));
    const LatSec = Math.floor(Math.abs(LatMin - (convertLat - LatDeg) * 60) * 60);

    return '- ' + LatDeg + 'º ' + LatMin + "' " + LatSec + "'' ";
  }

  formatValue(value) {
    if (value == null) {
      return 'Sem Informação.';
    }
    return parseFloat(value).toFixed(1);
  }

  getSensor(sensor) {
    if (sensor == null) {
      return '';
    }
    return this.graficoService.getSensor(sensor) + ': ';
  }
}
