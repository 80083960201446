import { Component, OnInit, ViewChild } from '@angular/core';
import { GraficoService } from '../../services/grafico.service';

@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.css']
})
export class GraficoComponent implements OnInit {

  codigo_estacao = 461;
  nome_estacao = 'BRASILIA';
  uf_estacao = 'DF';
  codigoOrigem = 'A001';

  isvisibleCharts = true;

  metadata_radiacao = {
    results: [
      {
        data: {},
        color: '',
        labels: []
      }
    ]
  };

  constructor(private graficoService: GraficoService) {
    this.graficoService.callComponetGrafico$.subscribe((data) => {
      this.codigoOrigem = data.codigoOrigem;
      this.codigo_estacao = data.codigo;
      this.graficoService.getEstacao(data.codigo).subscribe((res) => {
        this.nome_estacao = res['data']['list'][0].nome;
        this.graficoService.getMunicipio(res['data']['list'][0].municipio).subscribe((mun) => {
          this.uf_estacao = mun['data']['list'][0].uf;
        });
      });

    });
  }

  ngOnInit() { }

  visibleCharts() {
    this.isvisibleCharts = !this.isvisibleCharts;
  }

}
